<template>
  <div class="identifyResult">
    <template v-if="identify === true">
      <img src="./img/success.png" alt="" />
      <div class="tip">
        <div class="icon">
          <img src="./img/success_icon.png" alt="" />
        </div>
        <div class="text success">身份通过！</div>
      </div>
      <div
        class="subTip"
        v-if="
          successInfo.recognizeCntTotal !== '' &&
          successInfo.recognizeCntToday !== ''
        "
      >
        当前用户共被验证&nbsp;{{
          successInfo.recognizeCntTotal
        }}&nbsp;次，本次是今天验证的第&nbsp;{{
          successInfo.recognizeCntToday
        }}&nbsp;次
      </div>
      <div class="remark">{{ successInfo.remarks }}</div>
    </template>
    <template v-if="identify === false">
      <img src="./img/error.png" alt="" />
      <div class="tip">
        <div class="icon">
          <img src="./img/error_icon.png" alt="" />
        </div>
        <div class="text error">身份未通过！</div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  getCodeUrl,
  getSceneParamUrl,
  userLabelRecognizeProcessUrl,
} from "./api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount } from "@/utils/common";

export default {
  data() {
    return {
      identify: undefined,
      successInfo: {
        recognizeCntTotal: "",
        recognizeCntToday: "",
        remarks: "", // 匹配用户标签对应的备注
      },
      // paramId: "",
      params: {},
    };
  },
  computed: {
    // communityId() {
    //   return this.$store.state.communityId;
    // },
    userId() {
      return this.$store.state.userId;
    },
    // roomId() {
    //   return this.$store.state.houseId;
    // },
    // tenantId() {
    //   return this.$store.state.tenantId;
    // },
    // assetId() {
    //   return this.$store.state.assetId;
    // },
  },
  async created() {
    // this.paramId = this.$route.query.paramId;
    await this.getSceneParam();
    await this.getCode();
  },
  mounted() {
    gloabalCount("", 89, 1);
  },
  methods: {
    async getSceneParam() {
      let paramId = getHashParam("paramId");
      const res = await this.$axios.get(getSceneParamUrl, {
        params: {
          paramId,
        },
      });
      if (res.code === 200 && res.success) {
        this.params = res.data ? JSON.parse(res.data) : {};
      }
    },
    async getCode() {
      let params = {
        bizUserId: this.userId, // 商家用户
        cusUserId: this.params.userId, // 客户用户
      };
      let res = await this.$axios.get(userLabelRecognizeProcessUrl, { params });
      if (res.code === 200 && res.msg === "身份通过！") {
        this.identify = true;
        this.successInfo = res.data;
        return;
      }
      this.identify = false;
    },
  },
};
</script>
<style lang="less" scoped>
.identifyResult {
  box-sizing: border-box;
  min-height: 100%;
  text-align: center;
  > img {
    margin-top: 180px;
    width: 328px;
    height: 198px;
    vertical-align: middle;
    overflow: hidden;
  }
  .tip {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 42px;
      height: 42px;
      margin-right: 18px;
      > img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        vertical-align: middle;
      }
    }
    .text {
      font-size: 42px;
      font-weight: bold;
      &.success {
        color: #3781ff;
      }
      &.error {
        color: #e02020ff;
      }
    }
  }
  .subTip,
  .remark {
    word-wrap: break-word;
    word-break: break-all;
  }
  .subTip {
    padding: 32px 128px;
    color: #3781ff;
    font-size: 36px;
    font-weight: bold;
  }
  .remark {
    padding: 32px 128px;
    color: #3781ff;
    font-size: 36px;
    font-weight: bold;
  }
}
</style>
